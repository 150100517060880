import styles from "./AsyncBuilder.module.scss";
import Icon from "../icon/Icon";
import { IconNames } from "@/types/iconNames";
import { labels } from "@/utils/labels";
import { useMemo, useRef, useState } from "react";
import { AsyncBuilderPanel } from "./asyncBuilderPanel/AsyncBuilderPanel";
import useOutsideAlerter from "@/hooks/useClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadQuery, getFileNameList } from "@/utils/selectors";
import { SDK } from "@/types/aws";
import {
  resetAllError,
  setErrorCode,
  setErrorDescription,
} from "@/features/error/errorSlice";
import {
  removeDownloadQueryItem,
  removeFileName,
  toggleDownload,
  toggleWaitingDialog,
} from "@/features/download/downloadSlice";
import { removeTraceId } from "@/features/traceId/traceIdSlice";
import { Spinner } from "../spinner/Spinner";

export const AsyncBuilder = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const downloadQuery = useSelector(getDownloadQuery);
  const fileNameList = useSelector(getFileNameList);
  const dispatch = useDispatch();

  const downloadQueryValuesArray = useMemo(() => {
    const newArray: SDK[] = [];
    if (downloadQuery) {
      downloadQuery.map((item, index) => {
        const itemValues = Object.values(item)[0];

        // NOTE error handling
        if (itemValues.percentage === 500) {
          dispatch(setErrorCode(itemValues.percentage));
          dispatch(setErrorDescription(itemValues.status!));
          dispatch(toggleWaitingDialog(false));
          dispatch(removeDownloadQueryItem(itemValues.id));
          dispatch(removeTraceId(itemValues.id));
          dispatch(removeFileName(fileNameList[index]));
        } else {
          dispatch(resetAllError());

          const existingItemIndex = newArray.findIndex(
            (existingItem) => existingItem.id === itemValues.id
          );

          if (existingItemIndex !== -1) {
            newArray[existingItemIndex] = itemValues;
          } else {
            newArray.push(itemValues);
          }
        }
      });
    }
    return newArray;
  }, [downloadQuery]);

  /**
   * @description create a const that search if in downloadQuery some item has
   * a percentage that is different from 100%
   */
  const activeItemInDownloadQuery = useMemo(() => {
    return downloadQuery?.some((item) => {
      const itemValues = Object.values(item)[0];
      return itemValues.percentage !== 100;
    });
  }, [downloadQuery]);

  useOutsideAlerter({
    ref: panelRef,
    onClickOutside: () => setIsOpen(false),
  });

  return (
    <div ref={panelRef}>
      <button
        className={styles.container}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Async generation open panel action"
      >
        <span className={styles.iconContainer}>
          <Icon iconName={IconNames.TOOLS} color={"var(--color-primary)"} />
        </span>
        {labels.SDK_BUILDER}
        {activeItemInDownloadQuery && (
          <Spinner size={"xsmall"} containerClassname={styles.spinner} />
        )}
      </button>
      <AsyncBuilderPanel
        open={isOpen}
        title={labels.SDK_BUILDER}
        downloadQueryValuesArray={downloadQueryValuesArray}
      />
    </div>
  );
};
