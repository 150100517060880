import Image from "next/image";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { ButtonType, Size } from "@/types/types";
import { Spinner } from "../spinner/Spinner";
import Icon from "../icon/Icon";
import { IconNames } from "@/types/iconNames";

export type ButtonProps = {
  buttonText: string;
  ariaLabel: string;
  onClick: (e: any) => void;
  iconName?: IconNames;
  iconColor?: string;
  iconWidth?: number;
  iconHeight?: number;
  disabled?: boolean;
  isLoading?: boolean;
  customClassName?: string;
  size?: Size;
  type?: ButtonType;
};

export const Button = ({
  buttonText,
  ariaLabel,
  onClick,
  iconName,
  iconColor,
  iconWidth,
  iconHeight,
  disabled,
  isLoading,
  customClassName,
  size = Size.MEDIUM,
  type = ButtonType.PRIMARY_DARK,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      className={classNames(
        customClassName,
        styles.btn,
        styles[size],
        styles[type]
      )}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {!isLoading && (
        <>
          {iconName && (
            <p className={styles.icon}>
              <Icon
                iconName={iconName}
                color={iconColor || "var(--color-primary)"}
                width={iconWidth}
                height={iconHeight}
              />
            </p>
          )}
          {buttonText}
        </>
      )}
      {isLoading && (
        <Spinner size={"small"} containerClassname={styles.spinner} />
      )}
    </button>
  );
};
