import styles from "./Layout.module.scss";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PackType, Route } from "@/types/types";
import {
  getErrorCode,
  getErrorDescription,
  getPrevUrl,
  getEmail,
  getUrlParams,
  getWaitingStatus,
  getSelectedSoftwareCube1,
  getSelectedSoftwareCube2,
  getDownloadStatus,
  getTraceIdList,
  getSelectedRow,
  getActiveTab,
  getActiveItem,
  getLastTraceId,
  getAuthCode,
} from "@/utils/selectors";
import { ErrorBanner } from "../errorBanner/ErrorBanner";
import {
  cleanAllStates,
  startSubscribe,
  storeRefreshToken,
} from "@/utils/function";
import { Footer } from "../footer/Footer";
import { HamburgerMenu } from "../hamburgerMenu/HamburgerMenu";
import { setCookie } from "cookies-next";
import { Header } from "../header/Header";
import { setUrlParams } from "@/features/path/pathSlice";
import {
  useSendAuthCodeMutation,
  useUpsertUserMutation,
} from "@/pages/api/loginSlice";
import {
  setAuthCode,
  setBearerToken,
  setEmail,
  setExpirationTime,
  setIsLoggedIn,
  setUser,
} from "@/features/login/loginSlice";
import { WaitingDialog } from "../waitingDialog/WaitingDialog";
import {
  addFileName,
  toggleDownload,
  toggleWaitingDialog,
} from "@/features/download/downloadSlice";
import { usePathname, useSearchParams } from "next/navigation";
import { FeaturePack, SoftwarePackDependecy } from "@/types/history";
import { useGenerateSDKQuery } from "@/pages/api/apiSlice";
import { setIdsList } from "@/features/history/historySlice";
import {
  resetAllError,
  setErrorCode,
  setErrorDescription,
} from "@/features/error/errorSlice";
import { toggleCompleteSteps } from "@/features/step/stepSlice";

export type LayoutProps = {
  children: React.ReactNode;
  hasGradientBg?: boolean;
};

export const Layout = ({ children, hasGradientBg }: LayoutProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const dispatch = useDispatch();
  const prevUrl = useSelector(getPrevUrl);
  const errorCode = useSelector(getErrorCode);
  const errorDescription = useSelector(getErrorDescription);
  const userEmail = useSelector(getEmail);
  const urlParams = useSelector(getUrlParams);
  const waitingStatus = useSelector(getWaitingStatus);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDownloadPage, setIsDownloadPage] = useState<boolean>(false);

  const [sendAuthCode, { data: sendAuthCodeData }] = useSendAuthCodeMutation();
  const [upsertUser, { data: upsertUserData }] = useUpsertUserMutation();

  const selectedSoftwareCube1 = useSelector(getSelectedSoftwareCube1);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const downloadActive = useSelector(getDownloadStatus);
  const traceIdsList = useSelector(getTraceIdList);
  const selectedRow = useSelector(getSelectedRow);
  const activeTab = useSelector(getActiveTab);
  const historyActiveItem = useSelector(getActiveItem);
  const lastTraceId = useSelector(getLastTraceId);
  const hasAuthCode = useSelector(getAuthCode);

  const historyItemList = useMemo(() => {
    if (historyActiveItem) {
      if (historyActiveItem.packType === PackType.SOFTWARE) {
        const list = historyActiveItem.json[0]
          .dependencies as SoftwarePackDependecy[];
        return list.map((software) => software.softwareIdToUpdate);
      } else {
        const list = historyActiveItem.json as FeaturePack[];
        return list.map((feature) => feature.featureIdToUpdate);
      }
    } else {
      return [];
    }
  }, [historyActiveItem]);

  useEffect(() => {
    if (historyItemList) {
      const list = historyItemList.map((item) => item.toString());
      dispatch(setIdsList(list));
    }
  }, [historyItemList]);

  // NOTE chiamata generateSDK
  const { currentData: generateSDKData, error: generateSDKError } =
    useGenerateSDKQuery(
      {
        softwareList:
          selectedSoftwareCube2.length === 0
            ? selectedSoftwareCube1.map((software) => software.softwareId)
            : [],
        listFeatures:
          selectedSoftwareCube2.length > 0
            ? selectedSoftwareCube2.map((feature) => feature.featureId)
            : [],
        cube: activeTab,
        series: selectedRow?.seriesId!,
        refreshParam: lastTraceId,
      },
      {
        skip: !downloadActive,
      }
    );

  const handleBeforeHistoryChange = (
    url: string,
    { shallow }: { shallow: boolean }
  ) => {
    if (prevUrl === Route.DOWNLOAD) {
      cleanAllStates(dispatch);
    }
  };

  useEffect(() => {
    if (router.asPath === Route.DOWNLOAD) {
      setIsDownloadPage(true);
    }
  }, []);

  /**
   * @description When router change search if there are some paramethers in the url and set state searchParams whit them
   * Return undefined if there aren't parameters
   */
  useEffect(() => {
    router.events.on("beforeHistoryChange", handleBeforeHistoryChange);

    if (router.isReady) {
      const params = router.query;

      if (Object.keys(params).length !== 0) {
        dispatch(setUrlParams(params));
      }
    }

    return () => {
      router.events.off("beforeHistoryChange", handleBeforeHistoryChange);
    };
  }, [router, prevUrl]);

  useEffect(() => {
    if (userEmail) {
      setCookie("userLoggedIn", true);
    } else {
      setCookie("userLoggedIn", false);
    }
  }, [userEmail]);

  /**
   * @description When searchParams is define, it takes parameter 'code' from url and launch the sendAuthCode POST
   */
  useEffect(() => {
    console.log("urlParams", urlParams);
    if (urlParams) {
      const authCode = urlParams["code"];

      if (authCode && !hasAuthCode) {
        storeRefreshToken(authCode);
        sendAuthCode(authCode);
        dispatch(setAuthCode(true));
      }
    }
  }, [urlParams, router, pathname, searchParams]);

  /**
   * @description if sendAuthCode POST return a response, launch the upsertUser POST and save the bearer token
   */
  useEffect(() => {
    if (sendAuthCodeData) {
      dispatch(setExpirationTime(sendAuthCodeData.expires_in));
      dispatch(setIsLoggedIn(true));
      const idToken = sendAuthCodeData.id_token;
      const tokenType = sendAuthCodeData.token_type;
      dispatch(setBearerToken(tokenType + " " + idToken));
      upsertUser();
    }
  }, [sendAuthCodeData]);

  /**
   * @description if upsertUser POST return a response, save the user data in the store
   */
  useEffect(() => {
    if (upsertUserData) {
      dispatch(setEmail(upsertUserData.userId));
      dispatch(
        setUser(`${upsertUserData.givenName} ${upsertUserData.familyName}`)
      );
    }
  }, [upsertUserData]);

  /**
   * @description If generateSdk response code is 200:
   * start subription to aws amplify with the list of traceIds
   */

  useEffect(() => {
    if (generateSDKData?.sdkGuidReference && !generateSDKError) {
      dispatch(toggleWaitingDialog(true));
      startSubscribe(traceIdsList, dispatch);
    }
    if (generateSDKData && generateSDKData.filename) {
      if (router.asPath !== Route.DOWNLOAD) {
        dispatch(addFileName(generateSDKData.filename));
      }
    }
  }, [generateSDKData]);

  useEffect(() => {
    if (generateSDKError) {
      dispatch(toggleDownload(false));
      dispatch(toggleCompleteSteps(false));
      if ("data" in generateSDKError) {
        const errorDetails = generateSDKError.data as {
          errorCode: number;
          errorDescription: string;
        };

        dispatch(setErrorCode(errorDetails.errorCode));
        dispatch(setErrorDescription(errorDetails.errorDescription));
      }
    }
  }, [generateSDKError]);

  return (
    <>
      <Header isMenuOpen={isMenuOpen} onToggleMenu={setIsMenuOpen} />
      <main
        className={classNames(styles.container, {
          [styles.gradientBg]: hasGradientBg,
          [styles.dFlex]: isDownloadPage,
        })}
      >
        <HamburgerMenu isOpen={isMenuOpen} />
        <div className={styles.wrapper}>
          {errorCode && (
            <ErrorBanner
              isVisible
              errorCode={errorCode ?? undefined}
              hasCloseAction
              closeAction={() => dispatch(resetAllError())}
            >
              {errorDescription}
            </ErrorBanner>
          )}
          {children}
        </div>
        <WaitingDialog
          open={waitingStatus}
          closeBtnAction={() => dispatch(toggleWaitingDialog(false))}
        />
      </main>
      <Footer />
    </>
  );
};
